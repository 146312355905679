/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from "react";
import Markdoc from "@markdoc/markdoc";
import satori from 'satori';
import { ResponsiveImageType, StructuredTextGraphQlResponseRecord } from "react-datocms";
import { GET_ALL_BLOGS, GET_DATO_BLOG, GET_DATO_SLUG, GET_ALL_LPS, GET_DATO_LP, GET_ALL_GUIDES, GET_DATO_GUIDE, GET_ALL_CHANGELOG, GET_ALL_NETWORKS, GET_DATO_NETWORK, GET_ALL_ECOSYSTEMS, GET_DATO_CLASSC, GET_ALL_CLASSC, GET_ALL_ENDPOINTS, GET_DATO_ENDPOINT, GET_ALL_API_REF_GUIDE, GET_DATO_DOCUMENT, GET_ALL_IMAGES, GET_ALL_INTEGRATIONS, GET_DATO_SDKS, GET_ALL_GRK, GET_GRK , GET_PLATFORM_BANNER, GET_DATO_DOCUMENTATION, GET_ALL_DOCUMENTATIONS, GET_ALL_PRODUCT_PAGES, GET_ALL_PRESSES, GET_ALL_WEBSITE_PAGES, PRESS_PAGE, GET_ALL_GLOSSARIES, WEB3_RESOURCES, LISTICLES, AIRDROPS } from "./DatoQueries";
import { format, formatDistance } from "date-fns";
import { cookie_remove } from "./cookies";
import { rudderStackTrack } from "../Track";
import ACTIONS from "../EventLibrary";
import BannerTemplate from "../ImageTemplates/banner";
import GenericTemplate from "../ImageTemplates/generic";
import { Code, Table } from "@radix-ui/themes";
import Link from "next/link";
export const isProduction = process.env.NODE_ENV === "production";
export const API_KEY = isProduction ? "ckey_docs" : "ckey_docs";
export const API_BASE_URL = isProduction ? "https://api.covalenthq.com" : "http://127.0.0.1:8000";
export const SCOUT_API_BASE_URL = isProduction ? "https://api.covalenthq.com" : "http://127.0.0.1:9000";
export const TURNSTILE_SITE_KEY = isProduction ? "0x4AAAAAAAMXg-_zMMZJHzfs" : "3x00000000000000000000FF";
export const CLASS_C_URL = "https://api.covalenthq.com/v1/cq/covalent/app";
export const PLATFORM_ROUTE = "platform";
export const API_REFERENCE_ROUTE = "api";
export const COVALENT_GROUP = "group_d632592ddbde4a499452d25b";
const DATO_URL = "https://graphql.datocms.com/";
const DATO_TOKEN = "380267b561e61d45a77f86296ceb4d";
const blogs = require("../../data/dato-blogs.json");
const blogMetaData = require("../../data/blog-metadata.json");
const images = require("../../data/images.json");
const staff = require("../../data/dato-staff.json");
const diagrams = require("../../data/dato-diagrams.json");
const sdkHelpers = require('../../js/sdk-client-generator-helpers');

export const TOKEN_NAME = "CQT";

export const DOCUMENTATION_CATEGORIES = {
    UNIFIED_API: "unified-api",
    NETWORK: "covalent-network"
};
export interface IList<T> {
    items: T[];
}

export interface KeyCreditUsage {
    apikey: string;
    credits: number;
}

export interface IApiKey {
    ip_cidr_allowlist: any;
    domain_suffix_allowlist: any;
    web_locked: React.JSX.Element;
    id?: string;
    display_name: string;
    created_by?: IAuthUser;
    created_at?: string;
    is_paid: boolean;
}

export declare type IAPiKeyList = IList<IApiKey>;

export interface Action {
    id: number;
    action: string;
}

export interface AuditLog {
    id: string;
    apikey_id: string;
    created_by: IAuthUser;
    apikey: string;
    action: Action;
    created_at: string;
    group: IGroup;
    to_user: IAuthUser;
    properties: {
        [key: string]: string;
    };
}

export interface ApiUsage {
    credits_consumed: number;
    date: string;
    tx_success: number;
    tx_failure: number;
}

export interface IAuthUser {
    id: string;
    full_name: string;
    display_name: string;
    email: string;
    verified: boolean;
    invited: boolean;
    active: boolean;
    has_usable_password: boolean;
    group?: IGroup;
    group_admin: boolean;

    joined_at: string;
    last_login: string;
}

export interface IAuthLoginResponse {
    user: IAuthUser;
    token: string;
    api_key: string;
    landing_page: string | null;
}

export interface IGroup {
    is_reseller_user_group: IGroup | undefined;
    id: string;
    name: string;
    is_paid: boolean;
    is_free_trial_expired: boolean;
}


export interface IResponse<T> {
    data: T;
    error: boolean;
    error_code: number;
    error_message: string;
}

export interface AccountUsage {
    is_premium_credits: boolean;
    credits_consumed: number;
    date: string;
    tx_count: number;
    is_paid: boolean;
}

export interface CovalentItemResponse<T> {
    item: T;
    updated_at: string;
}

export interface AccountCreditUsage {
    date: string;
    credits_consumed: number | null;
    is_premium_credits: true;
    max_credits: number;
}

interface IntegrationStage {
    stage: string;
}

export interface Network {
    historicalBalances: any;
    nftAssetsAndMetadata: any;
    chainid: string;
    testnet: boolean;
    chainname: string;
    displayname: string;
    integrationStage: {
        stage: string;
    }
    docsException: boolean;
    imgsvg: ImageProps;
    imgwhite: ImageProps;
    imgblack: ImageProps;
    phantom: boolean;
    weight: number;

    blockExplorer: string;
    blockTime: number;

    url: string;
    nativegastoken: string;
    suspended: boolean;

    premiumbalances: boolean;
    premiumtransactions: boolean;
    premiumlogevents: boolean;

    testnetchainof: Network;
    blockexplorer: string;
    blocktime: string;

    appchainof: { chainname: string };

    integrationstage: IntegrationStage;

    increment: boolean;
}

export interface IStripeCustomer {
    id: string;
    credits_purchased: string;
    credits_consumed: string;
    credits_reported: string;
    product_lookup_key: string;
    flex_amount_spent: string;
    auto_scale_enabled: boolean;
    max_flex_billing_threshold: number;
    base_subscription_fee: string;
    created_by: IAuthUser;
    group: IGroup;
    subscription_status: string;
    last_reported_at: string;
    cancel_at: string;
}


export interface IStripeOutstandingInvoices {
    hosted_invoice_url: string;
    invoice_pdf: string;
    paid: boolean;
    status: string;
    created: number;
    amount_due: number;
    name: string
}

export function getAllChains(networks): Network[] {
    return networks;
}

export function getAllStaff(): Network[] {
    return staff.data.allStaffs;
}

export function getMainnets(networks): Network[] {
    return networks.filter((x) => !x.testnet);
}

export function getTestnetsForMainnet(networks, chainName: string): Network[] {
    return networks.filter((x) =>
        x.testnetchainof && x.testnetchainof.chainname === chainName);
}

export function getNetworkCount(networks) {
    return getAllChains(networks).filter((x) => !x.phantom).length;
}

export function getDatoBlog(title: string) {
    return blogs.data.allBlogs.filter((x) => x.title === title)[0];
}

export async function getDocmentation(slug, category) {
    try {
        const url = DATO_URL;
        const e = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${DATO_TOKEN}`,
                
                "X-Api-Version": "3"
            },
            body: JSON.stringify({
                query: GET_DATO_DOCUMENTATION,
                variables: {
                    slug,
                    category
                }
            }),
        });
        return await e.json();
    } catch {
        return null;
    }
}

export function getStaff(slug: string) {
    let avatar = staff.data.allStaffs.filter((x) => x.slug === slug)[0];
    if (!avatar) {
        avatar = staff.data.allStaffs.filter((x) => x.slug === "ganesh")[0];
    }
    return avatar;
}

export const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
};


export interface DiagramDato {
    displayname: string;
    product: "network" | "api";
    slug: string;
    caption: string;
    image: ImageProps;
}


export interface DocumentDato {
    displayname: string;
    product: string;
    slug: string;
    caption: string;
    document: { "id": string; "mimeType": string; "size": number; "url": string };
}

export interface APIEndpointInterface {
    id: string;
    _status: "published" | "draft";
    class: "Class A" | "Class B" | "Class C" | "Pricing";
    phantom: boolean;
    slug: string;
    title: string;
    grouping: string;
    children: Child[];
    creditrate: number;
    creditratemodel: "linear" | "fixed";
    usecase: string;
    description: string;
    beta: boolean;
    realtime: boolean | null;
}

interface Child {
    id: string;
}

export interface ClassC {
    application: {
        slug: string;
        _status: string;
        displayname: string;
        description: string;
        blockchains: [[Object]];
        imgwhite: { responsiveImage: [Object] };
    };
    description: string;
    samplewallet: string;
    endpointtype: string;
    params: {
        name: string;
        description: string;
        type: string;
        required: boolean;
        primer: boolean;
        global: boolean;
        pathParam: boolean;
        choices: [];
    };
    path: string;
    name: string;
    integration_stage_requirement: IntegrationStage;
}

export function getAllCAPIEndpoints(classcapiendpoints): ClassC[] {
    const classC = classcapiendpoints.data.allClasscEndpoints.sort((a: ClassC, b: ClassC) => {
        return a.application.slug.localeCompare(b.application.slug);
    });
    return classC;
}

export function filterApiEndpoints(apiendpoints, klass, grouping, subgroup: string | null = null): APIEndpointInterface[] {
    if (subgroup) {

        const phantom = getAllAPIEndpointData(apiendpoints, true).filter((c: any) => {
            return c.phantom && c.slug === subgroup;
        })[0];

        if (!phantom) {
            return [];
        }

        const children: Child[] = phantom["children"];

        const r: any[] = [];
        children.forEach((c: Child) => {
            apiendpoints.forEach((x) => {
                if (x.id === c.id) {
                    r.push(x);
                }
            });
        });

        return r;
    } else {
        return apiendpoints.filter((c: APIEndpointInterface) => {
            return !c.phantom && c.class === klass && c.grouping === grouping;
        });
    }
}

export function filterClassCApiEndpoints(classcapiendpoints, grouping): ClassC[] {
    const children = classcapiendpoints.data.allClasscEndpoints.filter((c: ClassC) => {
        return c.application.slug === grouping;
    });

    return children;
}

export function getAllClassCApiGroupings(classcapiendpoints) {
    const groups: any[] = [];
    classcapiendpoints.data.allClasscEndpoints.forEach((endpoint) => {
        if (!groups.includes(endpoint.application.slug)) {
            groups.push(endpoint.application.slug);
        }
    });
    return groups.sort((a: string, b: string) => {
        return a.localeCompare(b);
    });
}

export function getAllAPIGroupings(apiendpoints) {
    const groups: any[] = [];
    apiendpoints.forEach((endpoint) => {
        if (!groups.includes(endpoint.grouping)) {
            groups.push(endpoint.grouping);
        }
    });
    return groups;
}

export const getAllSubGroups = (apiendpoints) => {
    const groups = getAllPhantomGroups(apiendpoints).map((group) => {
        return group.slug;
    });

    return groups;
};

export const getSubGroup = (apiendpoints, endpoint) => {
    const enrichedData = getAllPhantomGroups(apiendpoints, endpoint.grouping).filter((ep) => ep.slug !== endpoint.grouping).map((group) => {
        return filterApiEndpoints(apiendpoints, endpoint.class, endpoint.grouping, group.slug).filter((ep) => !ep.phantom).map((e) => {
            return { ...e, subgroup: group.slug };
        });
    }).flat();

    if (enrichedData) {
        const enrichedEndpoint = enrichedData.filter((ep) => {
            return ep.slug === endpoint.slug;
        })[0];

        if (enrichedEndpoint && enrichedEndpoint.subgroup) {
            return enrichedEndpoint.subgroup;
        }
    }

};

export const getAllPhantomGroups = (apiendpoints, grouping: null | string = null) => {
    const endpoints = apiendpoints.filter((c: APIEndpointInterface) => {
        if (grouping) {
            return c.phantom && c.grouping === grouping;
        } else {
            return c.phantom;
        }
    });
    return endpoints;
};

export function getAllAPIEndpoints(apiendpoints): APIEndpointInterface[] {
    const endpoints: any[] = [];
    const phantoms = apiendpoints.filter((e) => e.phantom).filter((e) => {
        return !apiendpoints.filter((ep) => ep.id === e.children[0].id)[0].phantom;
    });

    phantoms.forEach((phantom) => {
        phantom.children.forEach((child) => {
            const endpoint = apiendpoints.filter((e) => e.id === child.id)[0];
            if (!endpoint.phantom) {
                endpoints.push(endpoint);
            } else {
                endpoint.children.forEach((child) => {
                    const ep = apiendpoints.filter((e) => e.id === child.id)[0];
                    endpoints.push(ep);
                });
            }
        });
    });
    return sortEndpointsByCategory(endpoints.filter((e) => e._status === "published"));
}

interface Type {
    "type": string;
    "object": {
        "typeName": {
            "name": string;
            "package": string;
        };
        "fields": {
            "fieldName": string;
            "type": {
                "type": string;
                "reference": {
                    "name": string;
                    "package": string;
                };
            };
        }[];
    };
}

interface ClassCEndpoint {
    description: string;
    samplewallet: string;
    endpointtype: "balances" | "transactions" | "marketdata" | "metadata";
    application: App;
}

interface App {
    slug: string;
    displayname: string;
    description: string;
    blockchains: Blockchain[];
    imgwhite: ImageProps;
}

interface Blockchain {
    chainname: string;
    imgblack: ImageProps;
    imgwhite: ImageProps;
}

export function getClassCApps(classC): App[] {

    const a = {};
    const apps: any[] = [];

    classC.data.allClasscEndpoints.map((x: ClassCEndpoint) => {
        if (a[x.application.slug]) {
            return;
        } else {
            a[x.application.slug] = x.application;
            apps.push(x.application);
        }
    });

    return apps.sort((x, y) => x.displayname < y.displayname ? -1 : 1);

}

export function getClassCTemplates(classC) {
    return classC.data.allClasscEndpoints.map((x) => {
        const y = { ...x };
        if (y.path.length > 0) {
            y.path = y.path + "/";
        }
        return y;
    });
}

export function hasClassCTemplate(classC, slug: string, endpointType: string) {
    const r = getClassCTemplates(classC).filter((x) => x.application.slug === slug && x.endpointtype === endpointType);
    return r.length == 0 ? false : true;
}

export function getClassCTemplate(classC, slug: string, endpointType: string) {
    const r = getClassCTemplates(classC).filter((x) => x.application.slug === slug && x.endpointtype === endpointType)[0];
    return r;
}

export function getBlogs() {
    return blogs.data.allBlogs;
}

export function getImages() {
    return images.data.allUploads;
}

export function getBlogMetaData() {
    return blogMetaData;
}

export function getAllCategories() {
    return ['product',];
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getClassCApp(classC, slug: string): App {
    return getClassCApps(classC).filter((x) => x.slug === slug)[0];
}

interface ImageProps {
    url: string;
    responsiveImage: ResponsiveImageType;
}

interface Ecosystem {
    name: string;
    description: string;
    category: string;
    product: string;
    url: string;
    prime: boolean;
    imgwhite: ImageProps;
}

export function getEcosystem(ecosystems, product: string): Ecosystem[] {
    const x = ecosystems.data.allEcosystems.filter((x) => x.product === product && x.imgwhite?.responsiveImage);

    if (product === "api") {
        return x.filter((y) => y.showcase).sort((a, b) => a.name.localeCompare(b.name));
    }

    return x;
}

const openapi = require("../../data/openapi.json");

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Param {

}

interface Template {
    params: Param[];
}

interface Endpoint {
    id: string;
    title: string;
    description: string;
    classType: string;
    classSubTypeDescription: string;
    path: string;
    templates: Template[];
    params: Param[];
}

const definitions = require("../../data/conjure-output.json");
export const getAllTypes = (): Type[] => {
    return definitions.types;
};

export function getType(name) {
    return getAllTypes().filter((type) => type.object.typeName.name === name)[0].object;
}

export function getAllEndpointDefinitions() {
    return definitions.services;
}

function _legacyMatch(endpoint) {

    if (endpoint.httpPath.startsWith("/v1/{chainName}/xyk/") || endpoint.httpPath.startsWith("/v1/xyk/")) {
        endpoint.httpPath = endpoint.httpPath.replace("xyk", "xy=k");
    }
    return endpoint;
}

export function getYamlEndpoint(slug) {
    for (let i = 0; i < definitions.services.length; i++) {
        for (let j = 0; j < definitions.services[i].endpoints.length; j++) {
            if (definitions.services[i].endpoints[j].tags[0] === slug) {
                return _legacyMatch(definitions.services[i].endpoints[j]);
            }
        }
    }

    throw new Error("handle me: " + slug);
}

export interface APIEndpointData extends APIEndpointInterface {
    [x: string]: any;
    id: string;
    description: string;
    classType: string;
    integration_stage_requirement: IntegrationStage;
    classSubTypeDescription: string;
    path: string;
    templates: Template[];
    params: Param[];
}

export function renderPricing(klass, creditrate, creditratemodel, creditrateunit) {
    const crm = creditratemodel === "fixed"
        ? " Credits"
        : `Credits per ${creditrateunit}`;

    return `${creditrate.toFixed(2)} ${klass === "Class C"
        ? "Credits" : crm}`;
}

export function getAllAPIEndpointData(apiendpoints, includePhantom = false): APIEndpointData[] {
    const allDatoEndpoints = includePhantom ? apiendpoints.filter((e) => e._status === "published") : getAllAPIEndpoints(apiendpoints); 
    let enrichedEndpoints = allDatoEndpoints.map((endpoint) => {

        try {
            const definition = getYamlEndpoint(endpoint.slug);
            const returnType = getType(definition.returns.reference.name);
            const enrichedEndpoint = { ...endpoint, ...definition, returnType };
            return enrichedEndpoint;
        } catch (err) {
            return { ...endpoint };
        }
    });
    if (!includePhantom) {
        enrichedEndpoints = enrichedEndpoints.filter((ep) => !ep.phantom);
        return enrichedEndpoints;
    } else {
        return enrichedEndpoints;
    }
}

export function enrichEndpointData(datoEndpointData): APIEndpointData {
    const definition = getYamlEndpoint(datoEndpointData.slug);
    const returnType = getType(definition.returns.reference.name);
    const enrichedEndpoint = { ...datoEndpointData, ...definition, returnType };
    return enrichedEndpoint;
}

export function sortEndpointsByCategory(endpoints) {
    return endpoints.sort((a: APIEndpointData, b: APIEndpointData) => {

        // eslint-disable-next-line no-prototype-builtins
        if (a.hasOwnProperty("grouping") && b.hasOwnProperty("grouping")) {
            if (a.grouping === "base" && b.grouping !== "base") {
                return 1;
            }

            if (a.grouping === "base" && b.grouping === "base") {
                return 0;
            }

            if (a.grouping !== "base" && b.grouping === "base") {
                return -1;
            }

            return a.grouping.localeCompare(b.grouping);
        } else {
            return 0;
        }

    });
}

export function getAPIEndpointData(apiendpoints, slug: string): APIEndpointData {
    const data = apiendpoints.filter((x) => x.slug === slug)[0];
    return data;
}

export function getAPIClassC(classcapiendpoints, slug, application): ClassC {
    const classC: ClassC = getAllCAPIEndpoints(classcapiendpoints).filter((x) => x.name === slug && x.application.slug === application)[0];
    return classC;
}


export function getAllOpenAPIEndpointData(): Endpoint[] {
    return openapi;
}

export function getOpenAPIEndpointData(endpointName: string): Endpoint {
    return getAllOpenAPIEndpointData().filter((x) => x.title === endpointName)[0];
}

interface Guide {
    _status: "published" | "draft";
    title: string;
    slug: string;
    grouping: "nft" | "balances" | "transactions" | "increment";
    createdAt: string;
    content: StructuredTextGraphQlResponseRecord;
    endpoints: {
        slug: string;
        title: string;
    };
    seo: {
        title: string;
        description: string;
    };
}

export function getAllGuides(guides): Guide[] {
    const PREVIEW = process.env.NEXT_PUBLIC_IS_PREVIEW;
    if (!PREVIEW) {
        return guides.filter((x) => x.revealed);
    } else {
        return guides;
    }
}

const createSDKTemplate = (language) => {
    return SDKs[language];
};

export const snakeToCamel = (str: string): string => {
    return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

export const kebabToCamel = (str: string): string => {
    return str.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
};

const renderType = (value, type) => {
    if (type === "boolean" || type === "integer" || type === "double" || type === "safelong") {
        return `${value}`;
    } else {
        return `"${value}"`;
    }
};

const renderTypePython = (value, type) => {
    if (type === "integer" || type === "double" || type === "safelong") {
        return `${value}`;
    } else if (type === "boolean") {
        return `${capitalizeFirstLetter(value)}`;
    } else {
        return `"${value}"`;
    }
};

const exceptions = [
    "getErc20TransfersForWalletAddress",
    "getTokenHoldersV2ForTokenAddress",
    "getBlockHeights",
    "getLogEventsByAddress",
    "getLogEventsByTopicHash",
    "getChainCollections",
    "getTokenIdsForContractWithMetadata",
    // getAllTransactionsForAddress is getRecentTransactionsForAddress renamed
    "getRecentTransactionsForAddress"
];

const noChainUsageSDKException = [
    "getAddressActivity",
    "getAllChainStatus",
    "getAllChains"
];

const exceptionForPagination = (name) => {
    if (exceptions.includes(name, 0)) {
        return true;
    } else {
        return false;
    }
};

const createPaginatedTypeScriptSDK = (url, apikey, category, name, endpointParams = []) => {
    endpointParams = noChainUsageSDKException.includes(name) ? endpointParams.filter(p => p[0] !== "chainId") : endpointParams;
    return `import { CovalentClient } from "@covalenthq/client-sdk";

const ApiServices = async () => {
    const client = new CovalentClient("${apikey ? apikey : "YOUR_API_KEY"}");
    try {
        for await (const resp of client.${category}.${name === "getRecentTransactionsForAddress" ? "getAllTransactionsForAddress" : name}(${endpointParams.filter((p) => !p[2]).map((p) => {
    const type = p[3];
    const value = p[1];
    const render = renderType(value, type);
    return render;
})}${endpointParams.filter((p) => p[2]).length !== 0 ? `, {${endpointParams.filter((p) => p[2]).map((p) => {
    const type = p[3];
    const value = p[1];
    const render = renderType(value, type);
    return `"${kebabToCamel(p[0])}": ${render}`;
})}}` : ""})) {
            console.log(resp);
        }
    } catch (error) {
        console.log(error.message);
    }
}
`;
};

const createTypeScriptSDK = (url, apikey, category, name, endpointParams = []) => {
    if (exceptionForPagination(name)) {
        return createPaginatedTypeScriptSDK(url, apikey, category, name, endpointParams);
    }
    endpointParams = noChainUsageSDKException.includes(name) ? endpointParams.filter(p => p[0] !== "chainId") : endpointParams;
    return `import { CovalentClient } from "@covalenthq/client-sdk";

const ApiServices = async () => {
    const client = new CovalentClient("${apikey ? apikey : "YOUR_API_KEY"}");
    const resp = await client.${category}.${name}(${endpointParams.filter((p) => !p[2]).map((p) => {
    const type = p[3];
    const value = p[1];
    const render = renderType(value, type);
    return render;
})}${endpointParams.filter((p) => p[2]).length !== 0 ? `, {${endpointParams.filter((p) => p[2]).map((p) => {
    const type = p[3];
    const value = p[1];
    const render = renderType(value, type);
    return `"${kebabToCamel(p[0])}": ${render}`;
})}}` : ""});
    console.log(resp.data);
}
`;
};

function firstCharToLower(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}
function firstCharToUpper(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const createPythonSDK = (url, apikey, category, name, endpointParams = []) => {
    if (exceptionForPagination(name)) {
        return createPaginatedPythonSDK(url, apikey, category, name, endpointParams);
    }
    endpointParams = noChainUsageSDKException.includes(name) ? endpointParams.filter(p => p[0] !== "chainId") : endpointParams;
    return `from covalent import CovalentClient

def main():
    c = CovalentClient("${apikey ? apikey : "YOUR_API_KEY"}")
    b = c.${camelToSnake(firstCharToLower(category))}.${camelToSnake(name)}(${endpointParams.filter((p) => !p[2]).map((p) => {
    const type = p[3];
    const value = p[1];
    const render = renderTypePython(value, type);
    return render;
})}${endpointParams.filter((p) => p[2]).length !== 0 ? `, ${endpointParams.filter((p) => p[2]).map((p) => {
    const type = p[3];
    const value = p[1];
    const render = renderTypePython(value, type);
    return `${kebabToSnake(p[0])}=${render}`;
})}` : ""})
    if not b.error:
        print(b.data)
    else:
        print(b.error_message)
`;
};

const createGolangSDK = (url, apikey, category, name, endpointParams = []) => {
    if (exceptionForPagination(name)) {
        return createPaginatedGolangSDK(url, apikey, category, name, endpointParams);
    }
    endpointParams = noChainUsageSDKException.includes(name) ? endpointParams.filter(p => p[0] !== "chainId") : endpointParams;
    return `package main

import (
    "fmt"
    "github.com/covalenthq/covalent-api-sdk-go/covalentclient"
    "github.com/covalenthq/covalent-api-sdk-go/chains"
    ${endpointParams.filter((p) => p[2]).length !== 0 ? "\"github.com/covalenthq/covalent-api-sdk-go/services\"" : ""}
)

func main():
    ${endpointParams.filter((p) => p[2]).length !== 0 ? `${endpointParams.filter((p) => p[2]).map((p) => {
        const type = p[3];
        const value = p[1];
        const render = renderType(value, type);
        return `${kebabToCamel(p[0])} := ${render}`;
    }).join("\n    ")}` : ""}
    var Client = covalentclient.CovalentClient("${apikey ? apikey : "YOUR_API_KEY"}")
    resp, err := Client.${category}.${firstCharToUpper(name === "getRecentTransactionsForAddress" ? "getAllTransactionsForAddress" : name)}(${endpointParams.filter((p) => !p[2]).map((p) => {
    const type = p[3];
    const value = p[1];
    const render = renderType(value, type);
    return render;
})}${endpointParams.filter((p) => p[2]).length !== 0 ? `, services.${firstCharToUpper(name === "getRecentTransactionsForAddress" ? "getAllTransactionsForAddress" : name)}QueryParamOpts{${endpointParams.filter((p) => p[2]).map((p) => {
    return `${firstCharToUpper(kebabToCamel(p[0]))}: &${kebabToCamel(p[0])}`;
})}}` : ""});
    if err != nil {
        fmt.Printf("error: %s", err)
    }
`;
};

const createPaginatedGolangSDK = (url, apikey, category, name, endpointParams = []) => {
    endpointParams = noChainUsageSDKException.includes(name) ? endpointParams.filter(p => p[0] !== "chainId") : endpointParams;
    return `package main

import (
    "fmt"
    "github.com/covalenthq/covalent-api-sdk-go/chains"
    "github.com/covalenthq/covalent-api-sdk-go/covalentclient"
    "github.com/covalenthq/covalent-api-sdk-go/services"
)
    
func main() {
    results := make(chan services.${name === "getRecentTransactionsForAddress" ? sdkHelpers.PAGINATED_ENDPOINTS_LINK_MAP[name] : sdkHelpers.PAGINATED_ENDPOINTS_MAP[name]}Result)
    go func() {
        var Client = covalentclient.CovalentClient("${apikey ? apikey : "YOUR_API_KEY"}")
        ${endpointParams.filter((p) => p[2]).length !== 0 ? `${endpointParams.filter((p) => p[2]).map((p) => {
        const type = p[3];
        const value = p[1];
        const render = renderType(value, type);
        return `${kebabToCamel(p[0])} := ${render}`;
    }).join("\n        ")}` : ""}
        // Call the function and pass parameters
        resultChan := Client.${category}.${firstCharToUpper(name === "getRecentTransactionsForAddress" ? "getAllTransactionsForAddress" : name)}(${endpointParams.filter((p) => !p[2]).map((p) => {
    const type = p[3];
    const value = p[1];
    const render = renderType(value, type);
    return render;
})}${endpointParams.filter((p) => p[2]).length !== 0 ? `, services.${firstCharToUpper(name === "getRecentTransactionsForAddress" ? "getAllTransactionsForAddress" : name)}QueryParamOpts{${endpointParams.filter((p) => p[2]).map((p) => {
    return `${firstCharToUpper(kebabToCamel(p[0]))}: &${kebabToCamel(p[0])}`;
})}}` : ""});

        // Receive values from the result channel
        for result := range resultChan {
            // Process each result as it becomes available
            results <- result
        }

        // Close the results channel when done
        close(results)
    }()
    // Now you can read values from the results channel as they arrive
    for result := range results {
        // Process each result
        if result.Err != nil {
            fmt.Printf("error: %s", result.Err)
        }
    }
}`;
};

const createPaginatedPythonSDK = (url, apikey, category, name, endpointParams = []) => {
    endpointParams = noChainUsageSDKException.includes(name) ? endpointParams.filter(p => p[0] !== "chainId") : endpointParams;
    return `from covalent import CovalentClient
import asyncio

async def main():
    c = CovalentClient("${apikey ? apikey : "YOUR_API_KEY"}")
    try:
        async for res in c.${camelToSnake(firstCharToLower(category))}.${camelToSnake(name === "getRecentTransactionsForAddress" ? "getAllTransactionsForAddress" : name)}(${endpointParams.filter((p) => !p[2]).map((p) => {
    const type = p[3];
    const value = p[1];
    const render = renderTypePython(value, type);
    return render;
})}${endpointParams.filter((p) => p[2]).length !== 0 ? `, ${endpointParams.filter((p) => p[2]).map((p) => {
    const type = p[3];
    const value = p[1];
    const render = renderTypePython(value, type);
    return `${kebabToSnake(p[0])}=${render}`;
})}` : ""}):
            print(res)
    except Exception as e:
        print(e)
asyncio.run(main())
`;
};

const SDKs = {
    "typescript": createTypeScriptSDK,
    "python-sdk": createPythonSDK,
    "golang-sdk": createGolangSDK,
};

export const getSDKTemplate = (language) => {
    if (Object.keys(SDKs).includes(language)) {
        const sdkTemplate = createSDKTemplate(language);
        return sdkTemplate;
    } else {
        return getCodeTemplate[language];
    }
};

export const getCodeTemplate = {
    shell: (url: string, apikey: string) => `curl -X GET ${url} \\
    -H 'Content-Type: application/json' \\
    -u ${apikey ? apikey : "YOUR_API_KEY"}: \\
     # The colon prevents curl from asking for a password`,
    javascript: (url: string , apikey: string) => `let headers = new Headers();
    headers.set('Authorization', "Bearer ${apikey ? apikey : "YOUR_API_KEY"}")

fetch("${url}", {method: 'GET', headers: headers})
  .then((resp) => resp.json())
  .then((data) => console.log(data));`,
    typescript: (url: string , apikey: string) => `import { CovalentClient } from "@covalenthq/client-sdk";
const ApiServices = async () => {
    const client = new Client(${apikey});
    const resp = await client.BalanceService.getTokenBalancesForWalletAddress("eth-mainnet", "WALLET_ADDRESS");
    console.log(resp.data);
}`,
    php: (url: string, apikey: string) => `<?php
require_once('vendor/autoload.php');
        
$client = new GuzzleHttpClient();
        
$response = $client->request('GET', '${url}', [
    'headers' => [
    'accept' => 'application/json',
    'Authorization' => 'Basic ' . base64_encode("${apikey ? apikey : "YOUR_API_KEY"}:"),
    ],
]);
    
echo $response->getBody();`,
    ruby: (url: string, apikey: string) => `require 'uri'
require 'net/http'
require 'openssl'
        
url = URI("${url}")
        
http = Net::HTTP.new(url.host, url.port)
http.use_ssl = true
        
request = Net::HTTP::Get.new(url)
request["accept"] = 'application/json'
request.basic_auth '${apikey ? apikey : "YOUR_API_KEY"}', ''
        
response = http.request(request)
puts response.read_body`,
    python: (url: string, apikey: string) => `import requests
from requests.auth import HTTPBasicAuth

url = "${url}"
        
headers = {
    "accept": "application/json",
}

basic = HTTPBasicAuth('${apikey ? apikey : "YOUR_API_KEY"}', '')
    
response = requests.get(url, headers=headers, auth=basic)
    
print(response.text)`

};

export const getCodeInstallation = {
    php: "composer require guzzlehttp/guzzle",
    python: "python -m pip install requests",
    pythonSDK: "pip3 install covalent-api-sdk",
    typescript: "npm install @covalenthq/client-sdk",
    golangSDK: "go get github.com/covalenthq/covalent-api-sdk-go"
};

const BASE = "https://api.covalenthq.com/v1/cq/covalent/app/new_covalent_docs";

interface Pagination {
    has_more: boolean;
    page_size: string;
    page_number: string;
}

export interface CovalentListResponse<T> {
    items: T[];
    pagination: Pagination;
    updated_at: string;
}


export interface Response<T> {
    data: T;
    error: boolean;
    error_code: number;
    error_message: string;
}

export interface ActiveAddresses {
    date: string | number | Date;
    chain_name: string;
    active_addresses: string;
    month: string;
}

export interface Transactions {
    chain_name: string;
    transaction_count: string;
}
interface GasSpent {
    chain_name: string;
    gas_paid_usd: number;
}
export interface ActiveTokens {
    chain_name: string;
    active_tokens: string;
}


export const GetAllDatoBlogs = async () => {
    let skip = 0;
    const limit = 100;
    let keepQuerying = true;
    let blogPosts: any = [];

    while (keepQuerying) {
        const posts = await api.get_all_dato_posts(limit, skip);
        if (posts.errors) {
            break;
        }
        blogPosts = blogPosts.concat(posts.data?.allBlogs);

        skip += limit;

        if (posts.data?.allBlogs.length < limit) {
            keepQuerying = false;
        }
    }
    return blogPosts;
};

export const GetAllDatoDocumentations = async () => {
    let skip = 0;
    const limit = 100;
    let keepQuerying = true;
    let docs: any = [];

    while (keepQuerying) {
        const posts = await api.get_all_dato_documentations(limit, skip);
        if (posts.errors) {
            break;
        }

        docs = docs.concat(posts.data?.allDocumentations);

        skip += limit;

        if (posts.data?.allDocumentations.length < limit) {
            keepQuerying = false;
        }
    }
    return docs;
};

export const GetAllPress = async () => {
    let skip = 0;
    const limit = 100;
    let keepQuerying = true;
    let press: any = [];

    while (keepQuerying) {
        const posts = await api.get_all_dato_press(limit, skip);
        if (posts.errors) {
            break;
        }

        press = press.concat(posts.data?.allPresses);

        skip += limit;

        if (posts.data?.allPresses.length < limit) {
            keepQuerying = false;
        }
    }
    return press;
};

export const GetAllProductPages = async () => {
    let skip = 0;
    const limit = 100;
    let keepQuerying = true;
    let pages: any = [];

    while (keepQuerying) {
        const posts = await api.get_all_product_pages(limit, skip);
        if (posts.errors) {
            break;
        }
        pages = pages.concat(posts.data?.allProductPages);

        skip += limit;

        if (posts.data?.allProductPages.length < limit) {
            keepQuerying = false;
        }
    }
    return pages;
};

export const GetAllWebsitePages = async () => {
    let skip = 0;
    const limit = 100;
    let keepQuerying = true;
    let pages: any = [];

    while (keepQuerying) {
        const posts = await api.get_all_website_pages(limit, skip);
        if (posts.errors) {
            break;
        }
        pages = pages.concat(posts.data?.allWebsitePages);

        skip += limit;

        if (posts.data?.allWebsitePages.length < limit) {
            keepQuerying = false;
        }
    }
    return pages;
};

const addAltTags = (images) => {
    const transformedImages = images.map((image) => {
        if (image?.responsiveImage?.alt === null) {
            image.responsiveImage.alt = image.filename;
            return image;
        } else {
            return image;
        }
    });
    return transformedImages;
};

export const GetAllDatoImages = async () => {
    let skip = 0;
    const limit = 100;
    let keepQuerying = true;
    let imagesAll: any = [];

    while (keepQuerying) {
        const imageArray = await api.get_all_dato_images(limit, skip);
        if (imageArray.errors) {
            break;
        }
        imagesAll = imagesAll.concat(imageArray.data?.allUploads);

        skip += limit;

        if (imageArray.data?.allUploads.length < limit) {
            keepQuerying = false;
        }
    }
    const _imagesAll = addAltTags(imagesAll);
    return _imagesAll;
};

export const GetAllNetworks = async () => {
    let skip = 0;
    const limit = 100;
    let keepQuerying = true;
    let networks: any = [];

    while (keepQuerying) {
        const result = await api.get_all_networks(limit, skip);
        if (result.errors) {
            break;
        }
        networks = networks.concat(result.data?.allBlockchains);

        skip += limit;

        if (result.data?.allBlockchains.length < limit) {
            keepQuerying = false;
        }
    }
    return networks;
};

export const GetAllGuides = async () => {
    let skip = 0;
    const limit = 100;
    let keepQuerying = true;
    let guides: any = [];

    while (keepQuerying) {
        const result = await api.get_all_dato_guides(limit, skip);
        if (result.errors) {
            break;
        }
        guides = guides.concat(result.data?.allGuides);

        skip += limit;

        if (result.data?.allGuides.length < limit) {
            keepQuerying = false;
        }
    }
    return guides;
};

export const GetAllGlossaries = async () => {
    let skip = 0;
    const limit = 100;
    let keepQuerying = true;
    let glossaries: any = [];

    while (keepQuerying) {
        const result = await api.get_all_glossaries(limit, skip);
        if (result.errors) {
            break;
        }
        glossaries = glossaries.concat(result.data?.allGlossaries);

        skip += limit;

        if (result.data?.allGlossaries.length < limit) {
            keepQuerying = false;
        }
    }
    return glossaries;
};

export const GetAllEndpoints = async () => {
    let skip = 0;
    const limit = 100;
    let keepQuerying = true;
    let endpoints: any = [];

    while (keepQuerying) {
        const result = await api.get_all_endpoints(limit, skip);
        if (result.errors) {
            break;
        }
        endpoints = endpoints.concat(result.data?.allApiendpoints);

        skip += limit;

        if (result.data?.allApiendpoints.length < limit) {
            keepQuerying = false;
        }
    }
    return endpoints;
};

export const GetPlatformBanner = async () => {
    const result = await api.get_platform_banner();
    if (result.data && result.data.platformBanner) {
        return result.data.platformBanner;
    }
};


export const GetAllDatoLPs = async () => {
    let skip = 0;
    const limit = 100;
    let keepQuerying = true;
    let lps: any = [];

    while (keepQuerying) {
        const posts = await api.get_all_dato_lps(limit, skip);
        if (posts.errors) {
            break;
        }
        lps = lps.concat(posts.data?.allLandingPages);

        skip += limit;

        if (posts.data?.allLandingPages.length < limit) {
            keepQuerying = false;
        }
    }
    return lps;
};

export const GetAllWeb3Resources = async () => {
    let skip = 0;
    const limit = 100;
    let keepQuerying = true;
    let resources: any = [];

    while (keepQuerying) {
        const posts = await api.get_all_web3_resources(limit, skip);
        if (posts.errors) {
            break;
        }
        resources = resources.concat(posts.data?.allWeb3Resources);

        skip += limit;

        if (posts.data?.allWeb3Resources.length < limit) {
            keepQuerying = false;
        }
    }
    return resources;
};

export const GetAllListiclePages = async () => {
    let skip = 0;
    const limit = 100;
    let keepQuerying = true;
    let lists: any = [];

    while (keepQuerying) {
        const posts = await api.get_all_web3_resource_lists(limit, skip);
        if (posts.errors) {
            break;
        }
        lists = lists.concat(posts.data?.allListiclePages);

        skip += limit;

        if (posts.data?.allListiclePages.length < limit) {
            keepQuerying = false;
        }
    }
    return lists;
};

export const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");

        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        cookie_remove(name);
    }
};

export const authPages = [
    { title: "Welcome to the GoldRush Platform", slug: "login", authRequired: false },
    { title: "Get started with GoldRush", slug: "register", authRequired: false },
    { title: "Verify your email", slug: "verify_email", authRequired: true },
    { title: "Create your group", slug: "create_group", authRequired: true },
    { title: "Lists", slug: "lists", authRequired: true },
    { title: "Loginx", slug: "loginx", authRequired: false },
    { title: "Welcome to the GoldRush Platform", slug: "goldrush-platform-register", authRequired: false },
    // {title: "Password reset" ,slug: "reset_password", authRequired: false},
    // {title: "Set password" ,slug: "set_password", authRequired: true},

];

export const platformPages = [
    { title: "Home", slug: `${PLATFORM_ROUTE}`, authRequired: true },
    { title: "API Keys", slug: `${PLATFORM_ROUTE}/apikey`, authRequired: true },
];

export const accountPages = [
    { title: "Account Overview", slug: "account", authRequired: true },
    { title: "Team", slug: "account/manage_teams", authRequired: true },
    { title: "Usage", slug: "account/usage", authRequired: true },
    { title: "Billing", slug: "account/billing", authRequired: true },
    { title: "Billing Success", slug: "account/billing/success", authRequired: true },
];

export const api = {
    async fetch<T>(url: string, args: any): Promise<T> {
        const argsWithDefaults = {
            "X-Requested-With": "www.com.covalenthq.docs.api",
            ...(args || {})
        };

        const response = await fetch(url, argsWithDefaults);

        if(!response.ok){
            const errorObject = {
                error_code: response.status,
                error: true,
                error_message: 'API Error: Unable to fetch data',
            };

            return errorObject as T;
        }

        const data = await response.json();
        return data as T;
    },
    async activeWallets(chain_name: string, granularity: string, start_date: string, end_date: string, validID): Promise<Response<CovalentListResponse<ActiveAddresses>> | null> {
        try {
            const chain = chain_name.replaceAll("-", "_"),
                start = start_date.replaceAll("/", "-"),
                end = end_date.replaceAll("/", "-"),

                url = granularity === "all"
                    ? `${CLASS_C_URL}/chain-stats/active-addresses/?key=${API_KEY}&chain-name=${chain}`
                    : `${CLASS_C_URL}/chain-stats/active-addresses/?key=${API_KEY}&granularity=${granularity}&chain-name=${chain}&start-date=${start}&end-date=${end}`;

            const e = await fetch(url, {
                headers: {
                    "X-Requested-With": "com.covalenthq.www.docs.api",
                    "X-CF-Turnstile-Token": validID
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async transactions(chain_name: string, granularity: string, start_date: string, end_date: string, validID): Promise<Response<CovalentListResponse<Transactions>> | null> {
        try {
            const chain = chain_name.replaceAll("-", "_"),
                start = start_date.replaceAll("/", "-"),
                end = end_date.replaceAll("/", "-"),
                url = granularity === "all"
                    ? `${CLASS_C_URL}/chain-stats/transaction-count/?key=${API_KEY}&chain-name=${chain}`
                    : `${CLASS_C_URL}/chain-stats/transaction-count/?key=${API_KEY}&granularity=${granularity}&chain-name=${chain}&start-date=${start}&end-date=${end}`;

            const e = await fetch(url, {
                headers: {
                    "X-Requested-With": "com.covalenthq.www.docs.api",
                    "X-CF-Turnstile-Token": validID
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async gas_spent(chain_name: string, validID): Promise<Response<CovalentListResponse<GasSpent>> | null> {
        try {
            const chain = chain_name.replace("-", "_"),
                url = `${BASE}/3_month_gas_spent_usd/?key=${API_KEY}&chain_name=${chain}`;

            const e = await fetch(url, {
                headers: {
                    "X-Requested-With": "com.covalenthq.www.docs.api",
                    "X-CF-Turnstile-Token": validID
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async active_tokens(chain_name: string, granularity: string, start_date: string, end_date: string, validID): Promise<Response<CovalentListResponse<ActiveTokens>> | null> {
        try {
            const chain = chain_name.replaceAll("-", "_"),
                start = start_date.replaceAll("/", "-"),
                end = end_date.replaceAll("/", "-"),
                url = granularity === "all"
                    ? `${CLASS_C_URL}/chain-stats/active-tokens/?key=${API_KEY}&chain-name=${chain}`
                    : `${CLASS_C_URL}/chain-stats/active-tokens/?key=${API_KEY}&granularity=${granularity}&chain-name=${chain}&start-date=${start}&end-date=${end}`;

            const e = await fetch(url, {
                headers: {
                    "X-Requested-With": "com.covalenthq.www.docs.api",
                    "X-CF-Turnstile-Token": validID
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async gas_guzzlers(chain_name: string, interval = 1, validID): Promise<Response<CovalentListResponse<ActiveAddresses>> | null> {
        try {
            const chain = chain_name.replace("-", "_"),
                url = `${BASE}/gas_guzzlers/?key=${API_KEY}&chain_name=${chain}&time_interval=${interval}`;

            const e = await fetch(url, {
                headers: {
                    "X-Requested-With": "com.covalenthq.www.docs.api",
                    "X-CF-Turnstile-Token": validID
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async gas_spenders(chain_name: string, interval = 1, validID): Promise<Response<CovalentListResponse<Transactions>> | null> {
        try {
            const chain = chain_name.replace("-", "_"),
                url = `${BASE}/gas_spenders/?key=${API_KEY}&chain_name=${chain}&time_interval=${interval}`;

            const e = await fetch(url, {
                headers: {
                    "X-Requested-With": "com.covalenthq.www.docs.api",
                    "X-CF-Turnstile-Token": validID
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async gas_prices(chain_name: string, interval = 1, validID): Promise<Response<CovalentListResponse<GasSpent>> | null> {
        try {
            const chain = chain_name.replace("-", "_"),
                url = `${BASE}/gas_prices/?key=${API_KEY}&chain_name=${chain}&time_interval=${interval}`;

            const e = await fetch(url, {
                headers: {
                    "X-Requested-With": "com.covalenthq.www.docs.api",
                    "X-CF-Turnstile-Token": validID
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async historical_gas_prices(chain_name: string, interval = 1, validID): Promise<Response<CovalentListResponse<ActiveTokens>> | null> {
        try {
            const chain = chain_name.replace("-", "_"),
                url = `${BASE}/historical_gas_prices/?key=${API_KEY}&chain_name=${chain}&time_interval=${interval}`;

            const e = await fetch(url, {
                headers: {
                    "X-Requested-With": "com.covalenthq.www.docs.api",
                    "X-CF-Turnstile-Token": validID
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_dato_lps(limit: number, skip: number): Promise<any> {
        const url = DATO_URL;
        const e = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${DATO_TOKEN}`,
                
                "X-Api-Version": "3"
            },
            body: JSON.stringify({
                query: GET_ALL_LPS(limit, skip)
            }),
        });
        return await e.json();
    },
    async get_all_web3_resources(limit: number, skip: number): Promise<any> {
        const url = DATO_URL;
        const e = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${DATO_TOKEN}`,
                
                "X-Api-Version": "3"
            },
            body: JSON.stringify({
                query: WEB3_RESOURCES(limit, skip)
            }),
        });
        return await e.json();
    },
    async get_all_web3_resource_lists(limit: number, skip: number): Promise<any> {
        const url = DATO_URL;
        const e = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${DATO_TOKEN}`,
                
                "X-Api-Version": "3"
            },
            body: JSON.stringify({
                query: LISTICLES(limit, skip)
            }),
        });
        return await e.json();
    },
    async get_dato_lp(slug: string): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_DATO_LP,
                    variables: {
                        slug
                    }
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_dato_posts(limit: number, skip: number): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_ALL_BLOGS(limit, skip)
                }),
                next: {
                    revalidate: 600, // 10 mins
                },
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_dato_documentations(limit: number, skip: number): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_ALL_DOCUMENTATIONS(limit, skip)
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_dato_press(limit: number, skip: number): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_ALL_PRESSES(limit, skip)
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_product_pages(limit: number, skip: number): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_ALL_PRODUCT_PAGES(limit, skip)
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_website_pages(limit: number, skip: number): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_ALL_WEBSITE_PAGES(limit, skip)
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_dato_images(limit: number, skip: number): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3",
                    "X-Include-Drafts": "true",
                },
                body: JSON.stringify({
                    query: GET_ALL_IMAGES(limit, skip)
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_dato_post(slug: string): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_DATO_BLOG,
                    variables: {
                        slug
                    }
                }),
                next: {
                    revalidate: 600, // 10 mins
                },
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_airdrops(): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: AIRDROPS
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_dato_slugs(): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_DATO_SLUG,
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_platform_banner(): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_PLATFORM_BANNER,
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_covalent_openapi(): Promise<any> {
        try {
            const url = "https://api.covalenthq.com/v1/openapiv2/?key=ckey_e984daf61c6f446f984b1dff624";
            const e = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async getCreditUsageForAccount(token: string): Promise<Response<CovalentItemResponse<AccountCreditUsage>>> {
        const url = `${API_BASE_URL}/_/apistats/aggregate/`;

        return api.fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    },
    async getApiUsageForAccount(token: string, duration: string, aggregate_by: string): Promise<Response<CovalentListResponse<AccountUsage>> | null> {
        try {
            const url = `${API_BASE_URL}/_/apistats/`;
            const params = new URLSearchParams();
            params.append("aggregate_by", aggregate_by);
            params.append("duration", duration);

            const e = await fetch(url + "?" + params, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_key_credit_usage(token: string): Promise<Response<KeyCreditUsage[]> | null> {
        try {
            const url = `${API_BASE_URL}/_/account/apicredits/`;

            const e = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async auth_covalent(token: string): Promise<IResponse<IAuthLoginResponse> | null> {
        try {
            const url = `${API_BASE_URL}/_/auth/`;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async signup_covalent(full_name: string, email: string, password: string, visitor_id: string, locale: string, timezone: string, gcpAccount, utmStore): Promise<IResponse<IAuthLoginResponse> | IResponse<IAuthUser> | null> {
        try {
            const url = `${API_BASE_URL}/_/auth/register/`;
            const e = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    full_name,
                    email,
                    password,
                    app_id: 0,
                    visitor_id,
                    locale,
                    timezone,
                    google_procurement_account_id: gcpAccount,
                    ...utmStore
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async magic_link_register(email: string, visitor_id: string, locale: string, timezone: string): Promise<IResponse<IAuthUser> | null> {
        try {
            const url = `${API_BASE_URL}/_/auth/magic_link/register/`;
            const e = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    email,
                    app_id: 0,
                    visitor_id,
                    locale,
                    timezone
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async magic_link_login(signature: string, auth_user: string, valid_until: string, extra: string, visitor_id: string, timezone: string, locale: string): Promise<IResponse<IAuthLoginResponse> | null> {
        try {
            const url = `${API_BASE_URL}/_/auth/magic_link/login/`;
            const e = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    signature,
                    auth_user,
                    valid_until,
                    extra,
                    visitor_id,
                    timezone,
                    locale
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async login_covalent(email: string, password: string, visitor_id: string, locale: string, timezone: string, gcpAccount): Promise<IResponse<IAuthLoginResponse> | null> {
        try {
            const url = `${API_BASE_URL}/_/auth/login/`;
            const e = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    email,
                    password,
                    visitor_id,
                    locale,
                    timezone,
                    google_procurement_account_id: gcpAccount,
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async reset_password_covalent(email: string): Promise<IResponse<IAuthLoginResponse> | null> {
        try {
            const url = `${API_BASE_URL}/_/auth/reset_password/`;
            const e = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    email,
                    app_id: 0
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async set_password_anon_covalent(user_id: string, token: string, password: string): Promise<IResponse<IAuthLoginResponse> | null> {
        try {
            const url = `${API_BASE_URL}/_/auth/set_password_anon/`;
            const e = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    user_id,
                    token,
                    password
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async set_password_covalent(token: string, password: string): Promise<IResponse<IAuthLoginResponse> | null> {
        try {
            const url = `${API_BASE_URL}/_/auth/set_password/`;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    password,
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async create_group_covalent(token: string, group_name: string, group_type: string, expected_usage_type: string, referral_origin: string, referral_details: string, claimed_purpose: string): Promise<any> {
        try {
            const url = `${API_BASE_URL}/_/auth/create_group/`;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    token: token,
                    group_name: group_name,
                    group_type: group_type,
                    expected_usage_type: expected_usage_type,
                    referral_origin: referral_origin,
                    referral_details: referral_details,
                    claimed_purpose: claimed_purpose
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async activate_account_covalent(activation_code: string): Promise<IResponse<IAuthLoginResponse> | null> {
        try {
            const url = `${API_BASE_URL}/_/auth/activate/${activation_code}`;
            const e = await fetch(url, {
                method: "GET"
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async quicknode_login(activation_code: string): Promise<IResponse<IAuthLoginResponse> | null> {
        try {
            const url = `${API_BASE_URL}/_/auth/quicknode_user/jwt/`;
            const body = {
                "jwt": activation_code
            };
            const e = await fetch(url, {
                method: "POST",
                body: JSON.stringify(body)
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async reset_password_confirm_covalent(userId: string | string[], token: string | string[]): Promise<IResponse<IAuthLoginResponse> | null> {
        try {
            const url = `${API_BASE_URL}/_/auth/reset_password_confirm/${userId}/${token}/`;
            const e = await fetch(url, {
                method: "POST"
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async invite_team_covalent(token: string, email: string): Promise<any> {
        try {
            const url = `${API_BASE_URL}/_/auth/invite_team/`;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    email
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async resend_email_covalent(email: string): Promise<any> {
        try {
            const url = `${API_BASE_URL}/_/auth/resend_activation/${email}`;
            const e = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    email,
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_key_audit_log(token: string, key: string): Promise<Response<CovalentListResponse<AuditLog>> | null> {
        try {
            const url = `${API_BASE_URL}/_/audit_log/key/${key}/`;
            const e = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_key_usage(token: string, apikey: string, duration: string, aggregate_by: string): Promise<Response<CovalentListResponse<ApiUsage>> | null> {
        try {
            const url = `${API_BASE_URL}/_/apistats/${apikey}/`;
            const params = new URLSearchParams();
            params.append("aggregate_by", aggregate_by);
            params.append("duration", duration);
            const e = await fetch(url + "?" + params, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async upgrade_key(token: string, apikey: string, params): Promise<Response<CovalentListResponse<ApiUsage>> | null> {
        try {
            const url = `${API_BASE_URL}/_/apikey/${apikey}/`;
            const e = await fetch(url, {
                method: "PUT",
                body: JSON.stringify(params),
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async list_team(token: string) {
        try {
            const url = `${API_BASE_URL}/_/auth/list_team/`;
            const e = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async toggle_active(token: string, user_id: string) {
        try {
            const url = `${API_BASE_URL}/_/auth/toggle_active/`;
            const e = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    user_id
                }),
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_stripe_checkout(token: string, lookup_keys: string[]): Promise<IResponse<string> | null> {
        try {
            const url = `${API_BASE_URL}/_/stripe/checkout/`;
            const e = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    lookup_keys
                }),
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async outstanding_stripe_customer(token: string): Promise<IResponse<CovalentListResponse<IStripeOutstandingInvoices>> | null> {
        try {
            const url = `${API_BASE_URL}/_/stripe/customer/outstanding-invoices`;
            const e = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_stripe_customer(token: string): Promise<IResponse<CovalentItemResponse<IStripeCustomer>> | null> {
        try {
            const url = `${API_BASE_URL}/_/stripe/customer/`;
            const e = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_stripe_portal(token: string): Promise<IResponse<string> | null> {
        try {
            const url = `${API_BASE_URL}/_/stripe/portal/`;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async edit_stripe_customer(token: string, auto_scale_enabled: boolean, max_flex_billing_threshold: number | null): Promise<IResponse<string> | null> {
        try {
            const url = `${API_BASE_URL}/_/stripe/customer/`;
            const e = await fetch(url, {
                method: "PUT",
                body: JSON.stringify({
                    auto_scale_enabled,
                    max_flex_billing_threshold
                }),
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async save_settings(token: string, full_name: string, display_name: string) {
        try {
            const url = `${API_BASE_URL}/_/auth/save_settings/`;
            const e = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    full_name,
                    display_name
                }),
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_ecosystems(): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_ALL_ECOSYSTEMS,
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_networks(limit: number, skip: number): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_ALL_NETWORKS(limit, skip),
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_dato_guides(limit: number, skip: number): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                    // "X-Include-Drafts": "true",
                },
                body: JSON.stringify({
                    query: GET_ALL_GUIDES(limit, skip),
                }),
                next: {
                    revalidate: 600, // 10 mins
                },
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_glossaries(limit: number, skip: number): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_ALL_GLOSSARIES(limit, skip),
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_press_page(): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: PRESS_PAGE,
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_changelog(): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_ALL_CHANGELOG,
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_dato_network(slug: string): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_DATO_NETWORK,
                    variables: {
                        slug
                    }
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_dato_guide(slug: string): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                    // "X-Include-Drafts": "true",
                },
                body: JSON.stringify({
                    variables: {
                        slug
                    },
                    query: GET_DATO_GUIDE,
                }),
                next: {
                    revalidate: 600, // 10 mins
                },
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_classC(): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_ALL_CLASSC,
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_grk(): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`
                },
                body: JSON.stringify({
                    query: GET_ALL_GRK,
                }),
            });
            return await e.json();
        } catch(error) {
            throw new Error(error);
        }
    },
    async get_grk(slug: string): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_GRK,
                    variables: {
                        slug
                    }
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_integrations(): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_ALL_INTEGRATIONS,
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_dato_classC(slug: string): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_DATO_CLASSC,
                    variables: {
                        slug
                    }
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_endpoints(limit: number, skip: number): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_ALL_ENDPOINTS(limit, skip),
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    get_dato_diagram(slug: string) {
        try {
            const diagram = diagrams.data.allDiagrams.filter((d) => d.slug === slug)[0];
            return diagram;
        } catch {
            return null;
        }
    },
    async get_dato_endpoint(slug: string): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_DATO_ENDPOINT,
                    variables: {
                        slug
                    }
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_all_api_ref_guide(): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_ALL_API_REF_GUIDE
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_dato_document(slug: string): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_DATO_DOCUMENT,
                    variables: {
                        slug
                    }
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_dato_sdks(): Promise<any> {
        try {
            const url = DATO_URL;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${DATO_TOKEN}`,
                    
                    "X-Api-Version": "3"
                },
                body: JSON.stringify({
                    query: GET_DATO_SDKS,
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async get_typescript_version(): Promise<string | null> {
        try {
            const url = `https://registry.npmjs.org/@covalenthq/client-sdk`;
            const response = await fetch(url, { method: "GET" });
            const data = await response.json();
            return data['dist-tags'].latest;
        } catch {
            return null;
        }
    },
    async get_python_version(): Promise<string | null> {
        try {
            const url = `https://pypi.org/pypi/covalent-api-sdk/json`;
            const response = await fetch(url, { method: "GET" });
            const data = await response.json();
            return data['info']['version'];
        } catch {
            return null;
        }
    },
    async get_golang_version(): Promise<string | null> {
        try {
            const url = `https://proxy.golang.org/github.com/covalenthq/covalent-api-sdk-go/@v/list`; // https://proxy.golang.org/github.com/covalenthq/covalent-api-sdk-go/@v/v0.0.4.info
            const response = await fetch(url, { method: "GET" });
            const data = await response.text();
            // Split the response data by newline to get individual versions
            const versions = data.split('\n').filter(version => version.trim() !== '');

            // Sort the versions in descending order (latest version first)
            versions.sort((a, b) => b.localeCompare(a));

            // Get the most recent version
            if (versions.length > 0) {
                const mostRecentVersion = versions[0];
                return mostRecentVersion;
            } else {
                return null;
            }
        } catch {
            return null;
        }
    },
    async get_all_api_keys(token: string): Promise<IResponse<IAPiKeyList> | null> {
        try {
            const url = `${API_BASE_URL}/_/apikey/`;
            const e = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async team_list(token: string): Promise<any> {
        try {
            const url = `${API_BASE_URL}/_/auth/list_team/`;
            const e = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async create_api_keys(token: string, display_name: string, is_paid: boolean): Promise<IResponse<IApiKey> | null> {
        try {
            const url = `${API_BASE_URL}/_/apikey/`;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    display_name,
                    is_paid
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },
    async delete_api_keys(token: string, apikeyId: string): Promise<IResponse<IApiKey>> {
        const url = `${API_BASE_URL}/_/apikey/${apikeyId}/`;

        return api.fetch(url, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

    },
    async helpscout_message(token: string, subject: string, type, name, billing_email, email, endpoint, chain, message): Promise<IResponse<IApiKey> | null> {
        try {
            const url = `https://covalent-serverless.vercel.app/api/helpscout`;
            const e = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    subject,
                    type,
                    name,
                    billing_email,
                    email,
                    endpoint,
                    chain,
                    message
                }),
            });
            return await e.json();
        } catch {
            return null;
        }
    },

};

export const convertMarkdownToHTML = (markdownString: string) => {
    const ast = Markdoc.parse(markdownString);
    const content = Markdoc.transform(ast, /* config */);

    const html = Markdoc.renderers.html(content);
    return html;
};

const RenderTableTag = ({tag, id}) => {
    if (!tag.name) {
        return <Fragment key={id}>{tag}</Fragment>;
    } else if (tag.name === "a") {
        return <Link key={id} href={tag.attributes.href}>{
            tag.children && tag.children.map((tag, index) => <RenderTableTag id={id + index} key={id + index} tag={tag}/>)
        }</Link>;
    } else if (tag.name === "code") {
        return <Code key={id} color="gray">{
            tag.children && tag.children.map((tag, index) => <RenderTableTag id={id + index} key={id + index} tag={tag}/>)
        }</Code>;
    } else if (tag.name === "strong") {
        return <Fragment key={id}>{
            tag.children && tag.children.map((tag, index) => <RenderTableTag id={id + index} key={id + index} tag={tag}/>)
        }</Fragment>;
    } else if (tag.name === "th") {
        return <Table.RowHeaderCell key={id} align={tag.attributes.align}>{
            tag.children && tag.children.map((tag, index) => <RenderTableTag id={id + index} key={id + index} tag={tag}/>)
        }</Table.RowHeaderCell>;
    } else if (tag.name === "table") {
        return <Table.Root variant="surface" key={id}>
            {
                tag.children && tag.children.map((tag, index) => <RenderTableTag id={id + index} key={id + index} tag={tag}/>)
            }
        </Table.Root>;
    } else if (tag.name === "tbody") {
        return <Table.Body key={id}>
            {
                tag.children && tag.children.map((tag, index) => <RenderTableTag id={id + index} key={id + index} tag={tag}/>)
            }
        </Table.Body>;
    } else if (tag.name === "thead") {
        return <Table.Header key={id}>
            {
                tag.children && tag.children.map((tag, index) => <RenderTableTag id={id + index} key={id + index} tag={tag}/>)
            }
        </Table.Header>;
    } else if (tag.name === "tr") {
        return <Table.Row key={id}>
            {
                tag.children && tag.children.map((tag, index) => <RenderTableTag id={id + index} key={id + index} tag={tag}/>)
            }
        </Table.Row>;
    } else if (tag.name === "td") {
        return <Table.Cell key={id} align={tag.attributes.align}>
            {
                tag.children && tag.children.map((tag, index) => <RenderTableTag id={id + index} key={id + index} tag={tag}/>)
            }
        </Table.Cell>;
    } else if (tag.name === "article") {
        return <Fragment key={id}>{tag.children.map((tag, index) => <RenderTableTag id={id + index} key={id + index} tag={tag}/>)}</Fragment>;
    } else {
        return null;
    }
};

export const convertMarkdownTable = (markdownString: string, id) => {
    const ast = Markdoc.parse(markdownString);
    const content = Markdoc.transform(ast, /* config */);

    return <RenderTableTag tag={content} id={id}/>;
};

export const mountHelpScout = (isPremium) => {
    const beacon = document.getElementById("beacon-container");
    if (isPremium && !beacon) {
        const helpscout = document.createElement("script");
        helpscout.async = true;
        helpscout.id = "helpscout";
        const scriptText = document.createTextNode("!function(e,t,n){function a(){var e=t.getElementsByTagName(\"script\")[0],n=t.createElement(\"script\");n.type=\"text/javascript\",n.async=!0,n.src=\"https://beacon-v2.helpscout.net\",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],\"complete\"===t.readyState)return a();e.attachEvent?e.attachEvent(\"onload\",a):e.addEventListener(\"load\",a,!1)}(window,document,window.Beacon||function(){});window.Beacon('init', '3d82992f-8a31-4470-913b-5d06e855a2d7')");
        helpscout.appendChild(scriptText);
        document.body.appendChild(helpscout);
    } else if (!isPremium && beacon) {
        beacon.remove();
    }
};

export const latestGuide = async () => {
    const resp = await GetAllGuides();
    const __guides_lps = resp
        .filter((x) => x.grouping !== "increment")
        .filter((x) => x._status === "published")
        .sort((a, b) => a.title.localeCompare(b.title))[0];

    return __guides_lps;
};

export const navClassC = async () => {
    const resp = await api.get_all_classC();
    const apps = getClassCApps(resp)
        .map((x) => {
            return {
                href: `/docs/unified-api/class-c/${x.slug}/`,
                label: capitalizeFirstLetter(x.slug)
            };
        });
    return apps;
};

export const defaultCodeTemplates = [
    { name: "TypeScript", code: getSDKTemplate("typescript"), installation: getCodeInstallation.typescript },
    { name: "python", code: getSDKTemplate("python-sdk"), installation: getCodeInstallation.pythonSDK },
    { name: "go", code: getSDKTemplate("golang-sdk"), installation: getCodeInstallation.golangSDK },
    { name: "ruby", code: getSDKTemplate("ruby") },
    { name: "shell", code: getSDKTemplate("shell") },
];

export const defaultCodeTemplatesNoSDK = [
    { name: "shell", code: getSDKTemplate("shell") },
    { name: "JavaScript", code: getSDKTemplate("javascript") },
    { name: "ruby", code: getSDKTemplate("ruby") },
    { name: "python", code: getSDKTemplate("python"), installation: getCodeInstallation.python },
];

export const cleanUrl = (x) => {

    if (x === "IoTeX" || x === "DeFi Kingdoms") {
        return x.toLowerCase().replace(" ", "-");
    }
    if (x === "Milkomeda A1" || x === "Milkomeda C1") {
        return x.toLowerCase().replace(" ", "-");
    }
    if (x === "0xBattleGround") {
        return "0x-battleground";
    }
    if (x === "Horizen EON") {
        return "horizen";
    }
    if (x === "PLAYA3ULL Testnet") {
        return "playa3ull-testnet";
    }
    return x.toLowerCase().match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .join("-");
};

export function camelToKebab(camelCaseString: string) {
    return camelCaseString.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

function camelToSnake(str) {
    return str.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
}

function kebabToSnake(str) {
    return str.replace(/-/g, '_');
}

export const reverseCleanUrl = (x) => {
    if (x === "axie-ronin") {
        return "Axie/Ronin";
    }
    if (x === "cryptoblades-omnus") {
        return "CryptoBlades/Omnus";
    }
    if (x === "0x-battleground") {
        return "0xBattleGround";
    }
    if (x === "horizen") {
        return "Horizen EON";
    }

    return x.replaceAll("-", " ").replace(/[{()}]/g, "");
};

export const buildNetworksNav = (networks) => {

    const __network_lps = getAllChains(networks)
        .filter((x) => x.appchainof === null)
        .map((x): any => {

            const href = cleanUrl(x.displayname);

            const items = networks.filter((y) => {
                return y.appchainof && y.appchainof.chainname === x.chainname && y.testnet === false;
            }).map((z) => {
                return {
                    href: `/docs/networks/${cleanUrl(z.displayname)}`,
                    label: z.displayname
                };
            });

            if (items.length > 0) {
                const items2: any = (x.phantom ? [] : [{
                    href: `/docs/networks/${href}`,
                    label: `${x.displayname} Mainnet`,
                    icon: x.imgsvg.url,
                    testnet: x.testnet,
                    docsException: x.docsException,
                    appchainof: x.appchainof,
                }]).concat(items);

                return {
                    href: `/docs/networks/${href}`,
                    label: `${x.displayname}`,
                    icon: x.imgsvg.url,
                    testnet: x.testnet,
                    appchainof: x.appchainof,
                    docsException: x.docsException,
                    weight: x.weight,
                    suspended: x.suspended,
                    items: items2
                };

            } else {
                return {
                    href: `/docs/networks/${href}`,
                    label: x.displayname,
                    icon: x.imgsvg.url,
                    testnet: x.testnet,
                    docsException: x.docsException,
                    appchainof: x.appchainof,
                    weight: x.weight,
                    suspended: x.suspended,
                };
            }
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    const network_lps = __network_lps.filter((x) => x.weight != null).sort((a, b) => a.weight - b.weight)
        .concat(__network_lps.filter((x) => x.weight == null && !x.suspended));
    return [
        {
            title: "Supported Networks",
            topRoute: "/docs/networks",
            links: [
                { href: "/docs/networks", label: "Overview" },
                // { href: "/docs/networks/integration-stages", label: "Integration Stages" },
                ...network_lps.filter((x) => !x.testnet || x.docsException),
                { href: "", label: "Suspended", divider: true },
                ...__network_lps.filter((x) => x.suspended)
            ],
        },
    ];

};

export const buildApiNav = (apiendpoints, classcapiendpoints) => {
    return [
        {
            title: "API Reference",
            topRoute: "/docs/" + API_REFERENCE_ROUTE,
            links: [
                {
                    href: "", label: "Class A", divider: true
                },
                {
                    href: "", label: "Balances", items: filterApiEndpoints(apiendpoints, "Class A", "balances").map((e) => {
                        return { href: `/docs/${API_REFERENCE_ROUTE}/` + e.grouping + "/" + e.slug, label: e.title };
                    })
                },
                {
                    href: "",
                    label: "NFT",
                    items: getAllPhantomGroups(apiendpoints, "nft").filter((ep) => ep.slug !== "nft").map((group) => {
                        return {
                            href: "",
                            label: group.title,
                            items: filterApiEndpoints(apiendpoints, "Class A", "nft", group.slug).filter((ep) => !ep.phantom).map((e) => {
                                return {
                                    href: `/docs/${API_REFERENCE_ROUTE}/` + e.grouping + "/" + e.slug,
                                    label: <span className={e.beta ? "beta" : ""}>{e.title}</span>
                                };
                            })
                        };
                    })
                },
                {
                    href: "", label: "Transactions", items: filterApiEndpoints(apiendpoints, "Class A", "transactions").map((e) => {
                        return { href: `/docs/${API_REFERENCE_ROUTE}/` + e.grouping + "/" + e.slug, label: e.title };
                    })
                },
                {
                    href: "", label: "Security", items: filterApiEndpoints(apiendpoints, "Class A", "security").map((e) => {
                        return { href: `/docs/${API_REFERENCE_ROUTE}/` + e.grouping + "/" + e.slug, label: e.title };
                    })
                },
                {
                    href: "", label: "Base", items: filterApiEndpoints(apiendpoints, "Class A", "base").map((e) => {
                        return { href: `/docs/${API_REFERENCE_ROUTE}/` + e.grouping + "/" + e.slug, label: e.title };
                    })
                },
                {
                    href: "", label: "Class B", divider: true
                },
                {
                    href: "", label: "xyk", items: filterApiEndpoints(apiendpoints, "Class B", "xyk").map((e) => {
                        return { href: `/docs/${API_REFERENCE_ROUTE}/` + e.grouping + "/" + e.slug, label: e.title };
                    })
                },
                {
                    href: "", label: "Pricing", divider: true
                },
                {
                    href: "", label: "pricing", items: filterApiEndpoints(apiendpoints, "Pricing", "pricing").map((e) => {
                        return { href: `/docs/${API_REFERENCE_ROUTE}/` + e.grouping + "/" + e.slug, label: e.title };
                    })
                },
                {
                    href: "", label: "Class C", divider: true
                },
                ...getAllClassCApiGroupings(classcapiendpoints).map((group) => {
                    return {
                        href: "", label: group, items: filterClassCApiEndpoints(classcapiendpoints, group).map((e) => {
                            return { href: `/docs/${API_REFERENCE_ROUTE}/` + e.application.slug + "/" + e.application.slug + "-" + e.endpointtype, label: e.name };
                        })
                    };
                }),
            ]
        }
    ];
};

// eslint-disable-next-line no-useless-escape
export const EMAIL_RE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const _emailNotOk = (email: string) => {
    const validEmail = EMAIL_RE.test(String(email).toLowerCase());
    if (!validEmail) {
        return true;
    }
    return false;

};

export const _loginFormNotOk = (email, password) => {
    const r = EMAIL_RE.test(String(email).toLowerCase());

    return !r || email.length === 0 || password.length === 0;
};


export const _registerFormNotOk = (visitor_id, email, full_name, password_strength) => {
    if (visitor_id.length == 0) {
        return true;
    }
    if (email.length < 5) {
        return true;
    }
    if (full_name.length < 3) {
        return true;
    }
    if (password_strength < 3) {
        return true;
    }

    const validEmail = EMAIL_RE.test(String(email).toLowerCase());
    if (!validEmail) {
        return true;
    }

    return false;
};


export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
};
export const timeFormatter = (time) => {
    return time ? formatDistance(new Date(time), new Date(), { addSuffix: true }) : "never";
};

export const timeRaw = (time) => {
    return time ? format(new Date(time), "PPpp") : "Never";
};
export const STRIPE_PRODUCT_MAP = {
    premium: {
        fixed: "professional_tier_fixed_price",
        flex: "flex_credit_professional_tier_price",
        name: "Professional",
        description: "Check in on your subscription status here where you can find important details such as your current consumption in this billing cycle as well as your max credit cap if you have disabled auto-scale. ",
        for: "Enhanced API access for companies that need to grow.",
        price: "250",
        FEATURES: ["Higher rate limits", "Auto-scaling Flex credits", "Premium support"]
    },
    increment: {
        fixed: "increment_standard_fixed_price",
        flex: "flex_credit_professional_tier_price",
        name: "Increment Professional",
        description: "Check in on your subscription status here where you can find important details such as your current consumption in this billing cycle as well as your max credit cap if you have disabled auto-scale. ",
        for: "Enhanced Increment access for start-ups that need to grow.",
        price: "500",
        FEATURES: ["Turn queries into custom endpoints", "Higher limits on query performance", "Premium support on Slack", "Custom labelling request"]
    },
    inner_circle: {
        fixed: "inner_circle_fixed_price",
        flex: "flex_credit_professional_tier_price",
        name: "Inner Circle",
        description: "Check in on your subscription status here, where you can find important details such as your Platform Fee and any credits purchased along with your current consumption. Please refer to your contract with Covalent for all other details or ask our team directly through our shared communication channels.",
        for: "For enterprise customers that need additional dedicated access, support, and more.",
        price: "Custom",
        FEATURES: ["Dedicated support", "Commercial agreements, SLAs and NDAs", "Early access to new products", "Personalized, white-glove service"]
    },
    free_api: {
        fixed: "free",
        flex: "free",
        name: "14 Day Free Trial",
        description: "Check in on your subscription status here where you can find important details such as your current consumption in this billing cycle as well as your max credit cap if you have disabled auto-scale. ",
        for: "For hobbyists and for those who want to start testing the Covalent GoldRush API.",
        price: "0",
        FEATURES: ["All GoldRush Endpoints", "Onchain data access via GoldRush SDKs", "Full historical data across all supported blockchains"]
    },
    free_increment: {
        fixed: "free",
        flex: "free",
        name: "14 Day Free Trial",
        description: "Check in on your subscription status here where you can find important details such as your current consumption in this billing cycle as well as your max credit cap if you have disabled auto-scale. ",
        for: "For Freelance Analysts and for those who want to start testing Increment.",
        price: "0",
        FEATURES: ["Full historical data for all supported blockchains", "No-code analystics with Increment's curated data models", "Unified tables across DEXs, NFTs, Lending and more", "Pre-built dashboards for DeFi & Token analytics"]
    },
};

const check_mark_icon = <span className="small-icon material-symbols-rounded ">check_circle</span>;
const dash_icon = <span className="small-icon material-symbols-rounded ">remove</span>;

export const API_TABLE_MAP = [
    {
        feature: "All GoldRush endpoints",
        free:check_mark_icon,
        premium:check_mark_icon,
        inner:check_mark_icon
    },
    {
        feature: "Onchain data access via GoldRush SDKs",
        free:check_mark_icon,
        premium:check_mark_icon,
        inner:check_mark_icon
    },
    {
        feature: "Higher Rate Limits",
        free: dash_icon,
        premium:check_mark_icon,
        inner:check_mark_icon
    },
    {
        feature: "Support",
        free: "Basic discord chat and email support",
        premium: "Premium Chat",
        inner: "Dedicated Account Management"
    },
    {
        feature: "Request per Second Limit",
        free: "4 RPS",
        premium: "50 RPS",
        inner: "Custom"
    },
    {
        feature: "Included Credits per Month",
        free: "25k",
        premium: "300k",
        inner: "Custom"
    },
    {
        feature: "Flex Credit Price",
        free: dash_icon,
        premium: "$0.00077 / 1 Credit",
        inner: "Custom"
    }
];


export const INCREMENT_TABLE_MAP = [
    {
        feature: "Custom Class-C Endpoints",
        free: dash_icon,
        premium:check_mark_icon,
        inner:check_mark_icon
    },
    {
        feature: "Self-Managed SQL Tables (beta)",
        free: dash_icon,
        premium:check_mark_icon,
        inner:check_mark_icon
    },
    {
        feature: "Query Timeout",
        free: "90 Seconds",
        premium: "1,000 Seconds",
        inner: "1,000 Seconds"
    },
    {
        feature: "Support",
        free: "Basic discord chat and email support",
        premium: "Premium Chat",
        inner: "Dedicated Account Management"
    },
    {
        feature: "Query Memory Size",
        free: "20 GB",
        premium: "120 GB",
        inner: "Custom"
    },
    {
        feature: "Included Credits per Month",
        free: "100k",
        premium: "100M",
        inner: "Custom"
    },
    {
        feature: "Flex Credit Price",
        free: dash_icon,
        premium: "$0.00077 / 1 Credit",
        inner: "Custom"
    }
];

export const STRIPE_PRODUCT_MAP_R = {
    self_serve_fixed_price: {
        name: "Professional",
        subname: "premium", 
        description: "Check in on your subscription status here where you can find important details such as your current consumption in this billing cycle as well as your max credit cap if you have disabled auto-scale. ",
        price: "250",
        FEATURES: ["Higher rate limits", "Faster Ethereum token balances than our competitors", "Auto-scaling Flex credits"]
    },
    self_serve_flex_price: {
        name: "Professional",
        subname: "premium",
        description: "Check in on your subscription status here where you can find important details such as your current consumption in this billing cycle as well as your max credit cap if you have disabled auto-scale. ",
        price: "250",
        FEATURES: ["Higher rate limits", "Faster Ethereum token balances than our competitors", "Auto-scaling Flex credits"]
    },
    professional_tier_flex_price: {
        name: "Professional",
        subname: "premium",
        description: "Check in on your subscription status here where you can find important details such as your current consumption in this billing cycle as well as your max credit cap if you have disabled auto-scale. ",
        price: "250",
        FEATURES: ["Higher rate limits", "Faster Ethereum token balances than our competitors", "Auto-scaling Flex credits"]
    },
    professional_tier_fixed_price: {
        name: "Professional",
        subname: "premium", 
        description: "Check in on your subscription status here where you can find important details such as your current consumption in this billing cycle as well as your max credit cap if you have disabled auto-scale. ",
        price: "250",
        FEATURES: ["Higher rate limits", "Faster Ethereum token balances than our competitors", "Auto-scaling Flex credits"]
    },
    increment_standard_fixed_price: {
        name: "Increment",
        subname: "increment",
        description: "Check in on your subscription status here where you can find important details such as your current consumption in this billing cycle as well as your max credit cap if you have disabled auto-scale. ",
        price: "500",
        FEATURES: ["Higher rate limits", "Faster Ethereum token balances than our competitors", "Auto-scaling Flex credits"]
    },
    inner_circle_fixed_price: {
        name: "Inner Circle",
        subname: "inner_circle",
        description: "Check in on your subscription status here, where you can find important details such as your Platform Fee and any credits purchased along with your current consumption. Please refer to your contract with Covalent for all other details or ask our team directly through our shared communication channels.",
        price: "1000",
        FEATURES: ["Dedicated support", "Commercial agreements and NDAs", "Early access to new products", "Personalized, white-glove service"]
    },
    inner_circle_flex_price: {
        name: "Inner Circle",
        subname: "inner_circle",
        description: "Check in on your subscription status here, where you can find important details such as your Platform Fee and any credits purchased along with your current consumption. Please refer to your contract with Covalent for all other details or ask our team directly through our shared communication channels.",
        price: "1000",
        FEATURES: ["Dedicated support", "Commercial agreements and NDAs", "Early access to new products", "Personalized, white-glove service"]
    }
};

export const SUBSCRIPTION_STATUS = {
    "past_due": {
        "label": "Past Due",
        "message": "Payment on the latest finalized invoice either failed or wasn’t attempted."
    },
    "canceled": {
        "label": "Canceled",
        "message": "The subscription has been canceled.",
    },
    "unpaid": {
        "label": "Unpaid",
        "message": "The latest invoice hasn’t been paid but the subscription remains in place."
    }
};


export const roundedDecimal = (num) => {
    return (Math.round(parseInt(num, 10) * 100) / 100).toFixed(2);
};

export const goToTop = (id) => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > 0) {
        window.requestAnimationFrame(() => goToTop(id));
        window.scrollTo(0, scrollTop - scrollTop / 8);
    }
    else {
        const nameField = document.getElementById(id);
        if (nameField) {
            nameField.focus();
        }
    }
};


export function isEmptyDocument(obj): boolean {
    if (!obj) {
        return true;
    }

    const document = obj.value;

    if (!document) {
        throw new Error(
            "Passed object is neither null, a Structured Text value or a DAST document",
        );
    }

    return (
        document.schema === "dast" &&
        document.document.children.length === 1 &&
        document.document.children[0].type === "paragraph" &&
        document.document.children[0].children.length === 1 &&
        document.document.children[0].children[0].type === "span" &&
        document.document.children[0].children[0].value === ""
    );
}

export const trackCTA = (pageType, h2, url, cta) => {
    rudderStackTrack(ACTIONS[`landing_${pageType}_ctaclick`], {
        value: {
            h2,
            url,
            cta
        }
    });
};


export const sortUserFirst = (a, b, id) => {
    const userId = id;
    if (a.created_by.id === userId && b.created_by.id === userId) {
        return 0;
    } else if (a.created_by.id !== userId && b.created_by.id === userId) {
        return 1;
    } else if (a.created_by.id === userId && b.created_by.id !== userId) {
        return -1;
    } else {
        return 0;
    }
};

export const getImage = (basename, images) => {
    const image = images.filter((image) => image.basename === basename)[0];
    if (!image) {
        return null;
    } else if (image.responsiveImage) {
        return image.responsiveImage;
    }
};

export const replaceImageValues = (obj: any, images: any): any => {
    if (Array.isArray(obj)) {
        return obj.map((element: any) => replaceImageValues(element, images));
    }

    if (typeof obj === 'object' && obj !== null) {
        const transformedObj: { [key: string]: any } = {};

        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    transformedObj[key] = replaceImageValues(obj[key], images);
                } else if (typeof obj[key] === 'string' && key.includes('image')) {
                    const image = getImage(obj[key], images);
                    if (image) {
                        transformedObj[key] = image;
                    } else {
                        transformedObj[key] = obj[key];
                    }
                } else {
                    transformedObj[key] = obj[key];
                }
            }
        }

        return transformedObj;
    }

    return obj;
};

export const convertNumberedKeysToArrays = (obj: { [key: string]: any }) => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(convertNumberedKeysToArrays);
    }

    const transformedObj: any = {};

    const keys = Object.keys(obj);
    const allKeysAreNumbers = keys.every((key) => /^\d+$/.test(key));

    if (allKeysAreNumbers) {
        const values = Object.values(obj);
        return values.map(convertNumberedKeysToArrays);
    }

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            transformedObj[key] = convertNumberedKeysToArrays(obj[key]);
        }
    }

    return transformedObj;
};

export const generateBannerImage = async (title: string, subtitle: string, dato: string, type = "banner") => {
    const svg = await satori(
        type === "banner" ?
            <BannerTemplate
                img={dato}
                title={title ? title : <div style={{display:"flex", flexDirection: "column"}}>One unified API <br></br> One billion possibilities</div>}
                subtitle={subtitle ? subtitle : "GoldRush API"}
            />
            :
            <GenericTemplate img={dato}
            />
        ,
        {
            width: 1920,
            height: 1080,
            fonts: []
        },
    );
    return svg;
};

export const convertSVG = (svg: string) => {
    return`data:image/svg+xml;base64,${btoa(svg)}`;
};

export function buildOgImage(title, subtitle) {
    return `https://og-generator-liart.vercel.app/api/param?title=${encodeURI(title)}&subtitle=${subtitle}`;
}

export const INTEGRATION_MAP = {
    "classA": "Class A",
    "classB": "Class B",
    "classC": "Class C",
    "humanDecodedTransactions": "Human Decoded Transactions",
    "increment": "Increment",
    "nftAssetAndMetadataCache": "NFT Asset and Metadata Cache",
    "tokenHolders": "Token Holders",
    "traces": "Traces"
};


export const COLORS: {
    [name: string]: {
        "50": string;
        "100": string;
        "200": string;
        "300": string;
        "400": string;
        "500": string;
        "600": string;
        "700": string;
        "800": string;
        "900": string;
    };
} = {
    slate: {
        "50": "#f8fafc",
        "100": "#f1f5f9",
        "200": "#e2e8f0",
        "300": "#cbd5e1",
        "400": "#94a3b8",
        "500": "#64748b",
        "600": "#475569",
        "700": "#334155",
        "800": "#1e293b",
        "900": "#0f172a",
    },
    gray: {
        "50": "#f9fafb",
        "100": "#f3f4f6",
        "200": "#e5e7eb",
        "300": "#d1d5db",
        "400": "#9ca3af",
        "500": "#6b7280",
        "600": "#4b5563",
        "700": "#374151",
        "800": "#1f2937",
        "900": "#111827",
    },
    zinc: {
        "50": "#fafafa",
        "100": "#f4f4f5",
        "200": "#e4e4e7",
        "300": "#d4d4d8",
        "400": "#a1a1aa",
        "500": "#71717a",
        "600": "#52525b",
        "700": "#3f3f46",
        "800": "#27272a",
        "900": "#18181b",
    },
    neutral: {
        "50": "#fafafa",
        "100": "#f5f5f5",
        "200": "#e5e5e5",
        "300": "#d4d4d4",
        "400": "#a3a3a3",
        "500": "#737373",
        "600": "#525252",
        "700": "#404040",
        "800": "#262626",
        "900": "#171717",
    },
    stone: {
        "50": "#fafaf9",
        "100": "#f5f5f4",
        "200": "#e7e5e4",
        "300": "#d6d3d1",
        "400": "#a8a29e",
        "500": "#78716c",
        "600": "#57534e",
        "700": "#44403c",
        "800": "#292524",
        "900": "#1c1917",
    },
    red: {
        "50": "#fef2f2",
        "100": "#fee2e2",
        "200": "#fecaca",
        "300": "#fca5a5",
        "400": "#f87171",
        "500": "#ef4444",
        "600": "#dc2626",
        "700": "#b91c1c",
        "800": "#991b1b",
        "900": "#7f1d1d",
    },
    orange: {
        "50": "#fff7ed",
        "100": "#ffedd5",
        "200": "#fed7aa",
        "300": "#fdba74",
        "400": "#fb923c",
        "500": "#f97316",
        "600": "#ea580c",
        "700": "#c2410c",
        "800": "#9a3412",
        "900": "#7c2d12",
    },
    amber: {
        "50": "#fffbeb",
        "100": "#fef3c7",
        "200": "#fde68a",
        "300": "#fcd34d",
        "400": "#fbbf24",
        "500": "#f59e0b",
        "600": "#d97706",
        "700": "#b45309",
        "800": "#92400e",
        "900": "#78350f",
    },
    yellow: {
        "50": "#fefce8",
        "100": "#fef9c3",
        "200": "#fef08a",
        "300": "#fde047",
        "400": "#facc15",
        "500": "#eab308",
        "600": "#ca8a04",
        "700": "#a16207",
        "800": "#854d0e",
        "900": "#713f12",
    },
    lime: {
        "50": "#f7fee7",
        "100": "#ecfccb",
        "200": "#d9f99d",
        "300": "#bef264",
        "400": "#a3e635",
        "500": "#84cc16",
        "600": "#65a30d",
        "700": "#4d7c0f",
        "800": "#3f6212",
        "900": "#365314",
    },
    green: {
        "50": "#f0fdf4",
        "100": "#dcfce7",
        "200": "#bbf7d0",
        "300": "#86efac",
        "400": "#4ade80",
        "500": "#22c55e",
        "600": "#16a34a",
        "700": "#15803d",
        "800": "#166534",
        "900": "#14532d",
    },
    emerald: {
        "50": "#ecfdf5",
        "100": "#d1fae5",
        "200": "#a7f3d0",
        "300": "#6ee7b7",
        "400": "#34d399",
        "500": "#10b981",
        "600": "#059669",
        "700": "#047857",
        "800": "#065f46",
        "900": "#064e3b",
    },
    teal: {
        "50": "#f0fdfa",
        "100": "#ccfbf1",
        "200": "#99f6e4",
        "300": "#5eead4",
        "400": "#2dd4bf",
        "500": "#14b8a6",
        "600": "#0d9488",
        "700": "#0f766e",
        "800": "#115e59",
        "900": "#134e4a",
    },
    cyan: {
        "50": "#ecfeff",
        "100": "#cffafe",
        "200": "#a5f3fc",
        "300": "#67e8f9",
        "400": "#22d3ee",
        "500": "#06b6d4",
        "600": "#0891b2",
        "700": "#0e7490",
        "800": "#155e75",
        "900": "#164e63",
    },
    sky: {
        "50": "#f0f9ff",
        "100": "#e0f2fe",
        "200": "#bae6fd",
        "300": "#7dd3fc",
        "400": "#38bdf8",
        "500": "#0ea5e9",
        "600": "#0284c7",
        "700": "#0369a1",
        "800": "#075985",
        "900": "#0c4a6e",
    },
    blue: {
        "50": "#eff6ff",
        "100": "#dbeafe",
        "200": "#bfdbfe",
        "300": "#93c5fd",
        "400": "#60a5fa",
        "500": "#3b82f6",
        "600": "#2563eb",
        "700": "#1d4ed8",
        "800": "#1e40af",
        "900": "#1e3a8a",
    },
    indigo: {
        "50": "#eef2ff",
        "100": "#e0e7ff",
        "200": "#c7d2fe",
        "300": "#a5b4fc",
        "400": "#818cf8",
        "500": "#6366f1",
        "600": "#4f46e5",
        "700": "#4338ca",
        "800": "#3730a3",
        "900": "#312e81",
    },
    violet: {
        "50": "#f5f3ff",
        "100": "#ede9fe",
        "200": "#ddd6fe",
        "300": "#c4b5fd",
        "400": "#a78bfa",
        "500": "#8b5cf6",
        "600": "#7c3aed",
        "700": "#6d28d9",
        "800": "#5b21b6",
        "900": "#4c1d95",
    },
    purple: {
        "50": "#faf5ff",
        "100": "#f3e8ff",
        "200": "#e9d5ff",
        "300": "#d8b4fe",
        "400": "#c084fc",
        "500": "#a855f7",
        "600": "#9333ea",
        "700": "#7e22ce",
        "800": "#6b21a8",
        "900": "#581c87",
    },
    fuchsia: {
        "50": "#fdf4ff",
        "100": "#fae8ff",
        "200": "#f5d0fe",
        "300": "#f0abfc",
        "400": "#e879f9",
        "500": "#d946ef",
        "600": "#c026d3",
        "700": "#a21caf",
        "800": "#86198f",
        "900": "#701a75",
    },
    pink: {
        "50": "#fdf2f8",
        "100": "#fce7f3",
        "200": "#fbcfe8",
        "300": "#f9a8d4",
        "400": "#f472b6",
        "500": "#ec4899",
        "600": "#db2777",
        "700": "#be185d",
        "800": "#9d174d",
        "900": "#831843",
    },
    rose: {
        "50": "#fff1f2",
        "100": "#ffe4e6",
        "200": "#fecdd3",
        "300": "#fda4af",
        "400": "#fb7185",
        "500": "#f43f5e",
        "600": "#e11d48",
        "700": "#be123c",
        "800": "#9f1239",
        "900": "#881337",
    },
};
